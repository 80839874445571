<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Consuntar comprobante electronico</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mt-4">
            <div class="row row-height mb-4">
                <div class="col-xl-5 col-lg-5 content-right card borderTopCard shadow-sm">
                    <form class="card-body">
                        <h3 class="card-title">Consultar comprobante</h3>
                        <div class="row">
                            <div class="form-group col-xl-6 col-lg-6">
                                <label>Tipo Comprobante <span class="text-danger"> (*)</span></label>
                                <select class="form-control" v-model="form.codtipo_comprobante" ref="codtipo_comprobante">
                                    <option v-for="(val, key) in tipo_comprobante" :key="key" :value="val.codsunat">{{val.descripcion}}</option>
                                </select>
                            </div>
                            <div class="form-group col-xl-6 col-lg-6">
                                <label>RUC Emisor<span class="text-danger"> (*)</span></label>
                                <input type="text" :placeholder="form.ruc_emisor" v-model="form.ruc_emisor" ref="ruc_emisor" class="form-control required text-right" maxlength="11" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6 col-lg-6">
                                <label>Serie - Número<span class="text-danger"> (*)</span></label>
                                <div class="input-group input-group-sm">
                                    <input type="text" class="form-control input-sm text-uppercase" maxlength="4" v-model="form.serie" ref="serie" placeholder="B001">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="padding: 0.3rem 0.75rem;">-</span>
                                    </div>
                                    <input type="text" class="form-control input-sm text-right" maxlength="8" v-model="form.correlativo" ref="correlativo" placeholder="1" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </div>
                            </div>

                            <div class="form-group col-xl-6 col-lg-6">
                                <label>Total<span class="text-danger"> (*)</span></label>
                                <input type="number" placeholder="0.00" v-model="form.total_venta" ref="total_venta" class="form-control input-sm required text-right" step="0.01">
                            </div>
                        </div>
                        
                        <div id="bottom-wizard">
                            <button :disabled="resultado.length>0" type="button" @click="consultarComprobante" class="btn btn-danger btn-block cursor-pointer mb-2">
                                <i class="fa fa-search"></i>
                                Buscar comprobante
                            </button>
                        </div>
                        <div class="form-group">
                            <div class="alert alert-warning" role="alert">
                                ¡Los campos que contengan <span class="text-danger"> (*)</span> son obligatorios!
                            </div>
                        </div>

                        <div v-if="errores!=''" class="form-group">
                            <div class="alert alert-danger" role="alert">
                                {{errores}}
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-xl-7 col-lg-7 content-left">
                    <div class="card">
                        <div class="card-header"><h3 class="card-title">Resultados de la búsqueda</h3></div>
                        <div class="card-body">
                            <p v-if="resultado.codigo_unico==''">No se han encontrado resultados.</p>
                            <div v-else>
                                <div class="row">
                                    <div class="col-md-4">
                                        <PdfThumbnail :fileUrl="base_url+'tiendaVirtual/comprobante_digital/pdf/'+resultado.codigo_unico" :width="120" :target="'_blank'"></PdfThumbnail>
                                        <button :class="['btn', 'mt-2', 'btn-danger', 'cursor-pointer']" @click="descargarComprobante(base_url+'tiendaVirtual/comprobante_digital/pdf/'+resultado.codigo_unico, resultado.nombre_archivo_comprobante+'.pdf')">
                                            <i class="fa fa-download ml-2" aria-hidden="true"></i>
                                            Descargar
                                        </button>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <a href="javascript:void(0);">
                                            <img src="/img/xml_thumbnails.png" alt="..." style="width:120px; height:202px; cursor: default;" class="img-thumbnail mb-3">
                                        </a>
                                        <button :class="['btn', 'btn-danger', 'cursor-pointer']" @click="descargarComprobante(base_url+'tiendaVirtual/comprobante_digital/xml/'+resultado.codigo_unico, resultado.nombre_archivo_comprobante+'.xml')">
                                            <i class="fa fa-download ml-2" aria-hidden="true"></i>
                                            Descargar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>
    
<script>
    import axios from 'axios';
    import {mapState, mapMutations, mapActions} from 'vuex';
    import PdfThumbnail from '@/components/PdfThumbnail.vue';
    export default {
        name:"consulta_comprobante",
        computed:{
            ...mapState(['empresa', 'sucursal_seleccionada']),
        },
        components: {
            PdfThumbnail,
		},
        created(){
            this.loadObjEmpresa();
            this.loadObjSucursalSeleccionada();
        },
        mounted(){
            this.getTipoComprobante();
            document.title = "Consulta tu comprobante | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"CLARO");
            this.form.ruc_emisor = this.empresa.ruc;
        },
        data(){
            return{
                tipo_comprobante:[],
                form:{
                    codtipo_comprobante:"03"//Boleta electronica por defecto
                    ,ruc_emisor:""
                    ,serie:""
                    ,correlativo:""
                    ,total_venta:""
                },
                resultado:{
                    des_obse:"",
                    codigo_unico:"",
                    nombre_archivo_comprobante:""
                },
                empleado_consulta:"",
                errores:"",
                base_url: `${window.baseUrl}`,
                countDown : 130,
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjEmpresa', 'loadObjSucursalSeleccionada']),
            getTipoComprobante(){
                this.setLoading(true);
                this.$http.get('tiendaVirtual/get_tipos_comprobantes').then((res)=>{
                    this.tipo_comprobante = res.data.comprobantes;
                }).finally(()=>{
                    this.setLoading(false);
                });
            },
            consultarComprobante(){
                if(!this.form.codtipo_comprobante){
                    this.errores = "Seleccione el tipo de comprobante";
                    this.$refs.codtipo_comprobante.focus();
					return;
                }else{
                    this.errores = "";
                }

                if(!this.form.ruc_emisor){
                    this.$refs.ruc_emisor.focus();
					return;
                }else if(this.form.ruc_emisor.length<11){
                    this.errores = "El RUC debe tener 11 digitos";
                    this.$refs.ruc_emisor.focus();
					return;
                }else{
                    this.errores = "";
                }

                if(!this.form.serie){
                    this.errores = "Ingrese la serie";
                    this.$refs.serie.focus();
					return;
                }else{
                    this.errores = "";
                }

                if(!this.form.correlativo){
                    this.errores = "Ingrese el correlativo o número";
                    this.$refs.correlativo.focus();
					return;
                }else{
                    this.errores = "";
                }

                if(!this.form.total_venta){
                    this.errores = "Ingrese el total de la operación";
                    this.$refs.total_venta.focus();
					return;
                }else{
                    this.errores = "";
                }

                this.setLoading(true);
                let parametros = "ruc_emisor="+this.form.ruc_emisor;
                    parametros+= "&tipo="+this.form.codtipo_comprobante;
                    parametros+= "&serie="+this.form.serie;
                    parametros+= "&correlativo="+this.form.correlativo;
                    parametros+= "&total="+this.form.total_venta;

                this.$http.get('tiendaVirtual/consultar_comprobante?'+parametros).then((res)=>{
                    if(res.data.result!=""){
                        this.resultado  = res.data.result;
                    }else{
                        this.resultado.des_obse     = "";
                        this.resultado.codigo_unico = ""
                        this.setLoading(false);
                    }
                });
            },
            descargarComprobante(file, label){
                this.setLoading(true);
                axios({
                    url: file,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', label);
                    document.body.appendChild(link);
                    link.click();
                    this.setLoading(false);
                });
            },
        }
    }
</script>